<template>
  <div class="list row">
    <h2>Prodaja</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      >
        Osveži tabelu
      </button>
      <button type="button" class="btn btn-success bi bi-plus" @click="addData">
        Dodaj novu
      </button>
    </div>
    <data-table
      url="selling"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      :extra_actions="extra_actions"
      ref="datatable"
      @yes="deleteData"
      name="Sellings"
      :modalTitle="title"
      @loaded="onLoaded"
    >
    <template v-slot:tableExtraOptions>
        <vue-excel-xlsx class="ms-5 btn btn-warning bi bi-download" :data="jsonData['data']"
          :columns="jsonData['columns']" file-name="prodaja" :file-type="'xlsx'" :sheet-name="'Export'">
          Download
        </vue-excel-xlsx>
      </template>
    </data-table>
  </div>
</template>

<script>
import SellingsDataService from "../../services/sellings.service";
// import SellingInvoiceDataService from "../../services/selling-invoice.service";
import articlesService from "../../services/articles.service";
import DataTable from "../widgets/DataTable.vue";
import ConfirmDialog from "../widgets/ConfirmDialog.vue";
import PrintDialog from "../widgets/PrintDialog.vue";
import con from "../../constants/constants";
import ListDialog from "../widgets/ListDialog.vue";
import utils from "../../utils/common";
import Files from "../files/Files.vue";

export default {
  name: "Sellings",
  components: {
    DataTable,
  },
  data() {
    return {
      jsonData: [],
      columns: [
        "createdAt",
        "id",
        "doc_nr",
        "Buyer.name",
        "bb_neto_eur",
        "brand",
        "model",
        "contract_nr",
        "registration_nr",
        "chassis_nr",
        "edit",
        "extra_actions",
      ],
      headers: {
        createdAt: "Datum",
        id: "RB.",
        doc_nr: "Broj ponude",
        "Buyer.name": "Kupac",
        bb_neto_eur: "Neto prodajna cena (EUR)",
        brand: "Marka",
        model: "Model",
        contract_nr: "Broj ugovora",
        registration_nr: "Registracija",
        chassis_nr: "Šasija",
        edit: "",
        extra_actions: "Upload | PR | AV | KR/R",
      },
      templates: {
        bb_neto_eur(h, row) {
          return Number.parseFloat(row.bb_neto_eur).toLocaleString("sr-RS", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
      actions: {
        btn1: {
          color: "primary",
          icon: "bi-pencil",
          action: (data) => {
            this.$router.push({
              path: `/sellings/${data.id}`,
            });
          },
        },
        btn2: {
          color: "warning",
          icon: "bi-ui-checks-grid",
          action: (data) => this.$router.push({
              path: `/sellings/form/${data.id}`,
            }),
        },
        delete: {
          color: "danger",
          icon: "bi-trash",
          action: (data) => this.deleteOrStorno(data),
        },
        print: {
          color: "success",
          icon: "bi-printer",
          action: (data) => this.printDialog(data),
        },
      },
      extra_actions: {
         btn1: {
          count: "files",
          color: "warning",
          icon: "bi-download",
          action: (data) => this.addFile(data),
        },
        btn2: {
          countIcon: "Proform",
          color: "info",
          icon: "bi-journal-plus",
          // pref:"PR",
          action: (data) => this.addDoc("Proform", data),
        },
        btn3: {
          countIcon: "Advance",
          color: "purple",
          icon: "bi-journal-plus",
          // pref:"AR",
          action: (data) => this.addDoc("Advance", data),
        },
        btn4: {
          countIcon: "Normal",
          color: "indigo",
          icon: "bi-journal-plus",
          // pref:"R",
          action: (data) => this.addDoc("Normal", data),
        },
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "id",
    };
  },
  computed: {
    taxRates() {
      return this.$store.state.app.taxRates;
    },
  },
  methods: {
    async refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$router.push({
        path: `/sellings/0`,
        params: { data: {} },
      });
    },
    deleteDialog(id) {
      this.$store.commit("app/showModal", {
        modal: ConfirmDialog,
        params: {
          message: "Da li ste sigurni?",
          title: "Obrisi prodaju: " + id,
          onYes: () => this.confirmYes(id),
          onNo: this.confirmNo,
          // foother: { isComponent: true, commponent: test },
        },
      });
    },
    confirmYes(id) {
      this.deleteData(id);
    },
    confirmNo() {
      this.$store.commit("app/hideModal");
    },
    // stornoDialog(data) {
    //   this.$store.commit("app/showModal", {
    //     modal: ConfirmDialog,
    //     params: {
    //       message: "Da li ste sigurni da zelite da stornirate racun?",
    //       title: "Storniranje racuna: " + data.id,
    //       onYes: () => this.confirmStorno(data),
    //       onNo: this.confirmNo,
    //       // foother: { isComponent: true, commponent: test },
    //     },
    //   });
    // },
    // confirmStorno(data) {
    //   data["sell_invoice"]["st_doc_nr"] = "S" + data["sell_invoice"]["doc_nr"];
    //   SellingInvoiceDataService.update(
    //     data["sell_invoice"]["id"],
    //     data["sell_invoice"]
    //   )
    //     .then(() => {
    //       this.$store.commit("app/showAlert", {
    //         type: "success",
    //         message: "Podaci su promenjeni!",
    //       });
    //       this.$store.commit("app/hideModal");
    //     })
    //     .catch((e) => {
    //       this.$store.commit("app/showAlert", {
    //         type: "danger",
    //         message: e,
    //       });
    //     });
    // },
    deleteData(id) {
      SellingsDataService.delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    deleteOrStorno(data) {
      if (data["invoices"] && data["invoices"].length > 0) {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: "Postoje povezani racuni. Brisanje nije dozvoljeno!",
          });
      } else {
        this.deleteDialog(data.id);
      }
    },
    printDialog(data) {
      if (data.id) {
        const reports = [
          { url: `/report/${data.id}/prod/po`, label: "Ponuda" },
        ];
        if (data["sell_proforms"]) {
          data["sell_proforms"].forEach((doc) => {
            reports.push({
              url: `/report/${data.id}/prod/pr/${doc.id}`,
              label: "Predracun broj: " + doc["doc_nr"],
            });
          });
        }
        let hasNormal = false;
        for (const invoice of data["invoices"]) {
          if (
            invoice["doc_nr"] &&
            invoice['invoice_type'] == 'Normal'
            // data["ino_buyer"] == false
          ) {
            hasNormal = true;
            // reports.push({
            //   url: `/report/${data.id}/prod/r`,
            //   label: "Racun broj: " + invoice["doc_nr"],
            // });
          }
          if (
            invoice["doc_nr"] &&
            invoice['invoice_type'] == 'Normal'
            // data["ino_buyer"] == true
          ) {
            // hasNormal = true;
            reports.push({
              url: `/report/${data.id}/prod/ir/${invoice.id}`,
              label:
                "Valutni Racun broj: " + invoice["doc_nr"],
            });
          }
          if (
            invoice["doc_nr"]  &&
            invoice['invoice_status'] == 'Deleted' &&
            invoice['invoice_type'] == 'Normal' &&
            data["ino_buyer"] == false
          ) {
            reports.push({
              url: `/report/${data.id}/prod/sr/${invoice.id}`,
              label: "Storno Racun broj: " +invoice["doc_nr"],
            });
          }
        }
        reports.push({
          url: `/report/${data.id}/prod/o`,
          label: "Odluka",
        });
        reports.push({
            url: `/report/${data.id}/prod/pu`,
            label: "Predugovor o prodaji vozila",
          });
        if (hasNormal) {
          reports.push({
            url: `/report/${data.id}/prod/u`,
            label: "Ugovor o prodaji vozila",
          });
        }
        reports.push({
          url: `/report/${data.id}/prod/m`,
          label: "Zapisnik o primopredaji i stanju vozila po ugovoru",
        });
        reports.push({
          url: `/report/${data.id}/prod/mo`,
          label: "Zapisnik o oštećenjima na vozilu",
        });

        this.$store.commit("app/showModal", {
          modal: PrintDialog,
          params: {
            title: "Stampa dokumenata za prodaju: " + data.id,
            message: "Izaberi tip stampe.",
            reports,
          },
        });
      }
    },
  
  addDoc(type, data) {
      if (data.invoices.filter((x) => x.invoice_type == type).length > 0) {
        const items = data.invoices.map((invoice) => {
          return invoice.invoice_type == type
            ? {
                label: `Status: <b>${
                  con.statuses[invoice.invoice_status].label
                }</b> Tip: <b>${con.type[invoice.invoice_type].label}</b> ${
                  invoice.doc_nr ? `Broj: <b>${invoice.doc_nr}</b>` : ""
                } Iznos BRUTO RSD: <b>${
                  invoice.invoice_items.length > 0
                    ? invoice.invoice_items.reduce((accumulator, object) => {
                        return Number.parseFloat(
                          Number.parseFloat(accumulator) +
                            object.price * object.quantity +
                            Number.parseFloat(object.tax)
                        ).toFixed(2);
                      }, 0)
                    : 0
                }</b> Uplaceno RSD: <b>${
                  invoice.invoice_payments.length > 0
                    ? invoice.invoice_payments.reduce((accumulator, object) => {
                        return Number.parseFloat(
                          Number.parseFloat(accumulator) +
                            Number.parseFloat(object.payment)
                        ).toFixed(2);
                      }, 0)
                    : 0
                } </b> `,
                url: `/invoice/${type}/${invoice.id}`,
                btnText: "Otvori",
                target: "blank",
                url2: `/invoice/print/${type}/${invoice.id}/1`,
                btnText2: "Print",
                target2: "blank",
                url3: `/invoice/print/${type}/${invoice.id}/0`,
                btnText3: "Print bez valute",
                target3: "blank",
              }
            : null;
        });
        const itemsByType = items.filter((item) => item != null);
        // console.log(data);
        this.$store.commit("app/showModal", {
          modal: ListDialog,
          params: {
            title: "Prodaja: " + data.id,
            message: `Povezani ${utils
              .typeName(type, 2)
              .toLowerCase()} za prodaju ${data.id}.`,
            items: itemsByType,
            actions:
              {
                    btn: {
                      color: "primary",
                      icon: "bi-journal-plus",
                      label: `Kreiraj ${utils.typeName(type, 1).toLowerCase()}`,
                      action: () => this.createDoc(type, data),
                    },
                  },
          },
        });
      } else {
        console.log({type, data});
        this.createDoc(type, data);
      }
    },
    async createDoc(type, data) {
    const tax = this.taxRates.find((t) => t.id == data.article.tax);
    const advance = type == 'Advance' ? await articlesService.getDefault("Advance"): null;
    console.log({advance});
    
    SellingsDataService.createDocument({
        id: data.id,
        type: type,
        exchange: data.exchange,
        exchange_date: data.exchange_date,
        exchange_value: data.exchange_value,
        customers_id: data.Buyer.id,
        items: [{
          quantity: 1,
          price: data.bb_neto_rsd,
          price_exc: data.bb_neto_eur,
          tax: data.bb_neto_rsd * (Number.parseFloat(tax.rate) / 100),
          tax_exc: data.bb_neto_eur * (Number.parseFloat(tax.rate) / 100),
          article_id: advance ? advance.data[0].id : data.article.id,
        }],
        
        })
        .then((res) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Dokument je kreiran!",
          });
          utils.confirmNo();
          this.$router.push({
            path: `/invoice/${type}/${res.data.data.id}`,
          });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    addFile(data) {
      console.log({ data });
      this.$store.commit("app/showModal", {
        modal: Files,
        params: {
          pid: data.id,
          parent: "selling",
          title: "Lista dokumenata",
        },
      });
    },
    onLoaded(data) {
      this.jsonData["data"] = data.data.rows.map((r) => {
        return {
          ...r,
          PR: r.invoices.filter((i) => i.invoice_type == "Proform").length,
          AV: r.invoices.filter((i) => i.invoice_type == "Advance").length,
          'KR/R': r.invoices.filter((i) => i.invoice_type == "Normal").length,
        };
        
      });
      const res = Object.entries(this.headers)
      .map((k) => {
          if (k[1]) {
            if (k[1] != "Upload | PR | AV | KR/R") {
              return {
                label: k[1],
                field: k[0],
              };
            } else {
              return null;
            }
          }
        })
        .filter((o) => !!o);
        res.push({
          label: "PR",
          field: "PR",
        });
        res.push({
          label: "AV",
          field: "AV",
        });
        res.push({
          label: "KR/R",
          field: "KR/R",
        });
        this.jsonData["columns"] = res;
    },
  },
};
</script>

<style>
.Sellings_table_row > td:nth-child(5){
  text-align: right;
  font-weight: bold;
}

.Sellings_table_row > td{
  /* max-width: 230px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Sellings_table_row > td:nth-child(4) {
  min-width: 250px;
  max-width: 250px;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>